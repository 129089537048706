import { isEmpty } from './string.helper';

export const stateList = {
  AL: { name: 'Alabama', abbrev: 'AL' },
  AK: { name: 'Alaska', abbrev: 'AK' },
  AS: { name: 'American Samoa', abbrev: 'AS' },
  AZ: { name: 'Arizona', abbrev: 'AZ' },
  AR: { name: 'Arkansas', abbrev: 'AR' },
  CA: { name: 'California', abbrev: 'CA' },
  CO: { name: 'Colorado', abbrev: 'CO' },
  CT: { name: 'Connecticut', abbrev: 'CT' },
  DC: { name: 'District Of Columbia', abbrev: 'DC' },
  DE: { name: 'Delaware', abbrev: 'DE' },
  FL: { name: 'Florida', abbrev: 'FL' },
  GA: { name: 'Georgia', abbrev: 'GA' },
  GU: { name: 'Guam', abbrev: 'GU' },
  HI: { name: 'Hawaii', abbrev: 'HI' },
  ID: { name: 'Idaho', abbrev: 'ID' },
  IL: { name: 'Illinois', abbrev: 'IL' },
  IN: { name: 'Indiana', abbrev: 'IN' },
  IA: { name: 'Iowa', abbrev: 'IA' },
  KS: { name: 'Kansas', abbrev: 'KS' },
  KY: { name: 'Kentucky', abbrev: 'KY' },
  LA: { name: 'Louisiana', abbrev: 'LA' },
  ME: { name: 'Maine', abbrev: 'ME' },
  MD: { name: 'Maryland', abbrev: 'MD' },
  MA: { name: 'Massachusetts', abbrev: 'MA' },
  MI: { name: 'Michigan', abbrev: 'MI' },
  MN: { name: 'Minnesota', abbrev: 'MN' },
  MS: { name: 'Mississippi', abbrev: 'MS' },
  MO: { name: 'Missouri', abbrev: 'MO' },
  MT: { name: 'Montana', abbrev: 'MT' },
  NE: { name: 'Nebraska', abbrev: 'NE' },
  NV: { name: 'Nevada', abbrev: 'NV' },
  NH: { name: 'New Hampshire', abbrev: 'NH' },
  NJ: { name: 'New Jersey', abbrev: 'NJ' },
  NM: { name: 'New Mexico', abbrev: 'NM' },
  NY: { name: 'New York', abbrev: 'NY' },
  NC: { name: 'North Carolina', abbrev: 'NC' },
  ND: { name: 'North Dakota', abbrev: 'ND' },
  MP: { name: 'Northern Mariana Islands', abbrev: 'MP' },
  OH: { name: 'Ohio', abbrev: 'OH' },
  OK: { name: 'Oklahoma', abbrev: 'OK' },
  OR: { name: 'Oregon', abbrev: 'OR' },
  PA: { name: 'Pennsylvania', abbrev: 'PA' },
  PR: { name: 'Puerto Rico', abbrev: 'PR' },
  RI: { name: 'Rhode Island', abbrev: 'RI' },
  SC: { name: 'South Carolina', abbrev: 'SC' },
  SD: { name: 'South Dakota', abbrev: 'SD' },
  TN: { name: 'Tennessee', abbrev: 'TN' },
  TX: { name: 'Texas', abbrev: 'TX' },
  UT: { name: 'Utah', abbrev: 'UT' },
  VT: { name: 'Vermont', abbrev: 'VT' },
  VA: { name: 'Virginia', abbrev: 'VA' },
  VI: { name: 'Virgin Islands', abbrev: 'VI' },
  WA: { name: 'Washington', abbrev: 'WA' },
  WV: { name: 'West Virginia', abbrev: 'WV' },
  WI: { name: 'Wisconsin', abbrev: 'WI' },
  WY: { name: 'Wyoming', abbrev: 'WY' },
  FM: { name: 'Federated States of Micronesia', abbrev: 'FM' },
  MH: { name: 'Marshall Islands', abbrev: 'MH' },
  PW: { name: 'Palau', abbrev: 'PW' },
  AE: { name: 'Armed Forces Africa \\ Canada \\ Europe \\ M.E.', abbrev: 'AE' },
  AA: { name: 'Armed Forces America', abbrev: 'AA' },
  AP: { name: 'Armed Forces Pacific', abbrev: 'AP' },
};
export const formatAddress = address => {
  let formattedAddress = null;
  if (
    address &&
    address.street &&
    address.city &&
    address.state &&
    address.zip
  ) {
    formattedAddress = `${address.street.trim()}, ${address.city.trim()}, ${address.state.trim()}, ${address.zip.trim()}`;
  }
  return formattedAddress;
};

export const getBillingAddress = (billingAddress, userInfo) => {
  let bAddress = null;
  const { address, city, state, zip } = billingAddress?.billingInfo ?? {};
  if (address && city && state && zip) {
    bAddress = {
      street: address,
      unit: '',
      city: city,
      state: state,
      zip: zip,
    };
    return bAddress;
  } else {
    return userInfo?.primaryAddress;
  }
};

export const getSmartySuggestedAddress = address => {
  return address
    .filter(address => address.formatSource === 'SMARTY_STREETS')
    .shift();
};

export const isSmartyValidationNeeded = (
  isEnabled,
  isNewAddress,
  billingAddress,
) => {
  if (isEnabled) {
    if (isNewAddress) {
      return true;
    } else if (isEmpty(billingAddress)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
