import { wrapService } from 'actions/service_wrapper.actions';
import { fetchApplyNowTracking } from 'externals/_services/tracking.service';
import {
  CLICK,
  CLICK_APPLY,
} from 'externals/_tracking/types/eventTypes.constants';
import { getMrphPageFormFactor } from 'helpers/metadataLogic.helpers';
import { getPartnersCutomTrackingParams } from 'helpers/partnerCustomParamsHelper';
import {
  isBoosterEnrolled,
  isEnrolledAndFunded,
  isEnrolledInCash,
  isEnrolledNotFunded,
  isNotEnrolled,
} from 'helpers/sesameCash.helpers';
import { getFullAttributes } from 'helpers/tracking.helpers';
import { buildEncodedLinkForTrackingApi } from 'helpers/path.helper';
import {
  convertProps,
  plantMetadataInLocalStorageAndReturnTransferUrl,
  setPartnerNameAndImageWithId,
} from 'partial/OfferManager/holder/genericOfferLogic';
import { BANKING_VERTICAL } from 'types/mrph.vertical.constants';
import { SELECT_ONE_CLICK_APPLICATION_OFFER } from 'types/oneClickApplication.constants';
import {
  SELECT_PROFILE_CONTAINER_DATA,
  SELECT_PROFILE_CONTAINER_DATA_ATTRIBUTE,
} from 'types/profileContainer.constants';
import {
  SELECT_TODAY_CONTAINER_DATA,
  SELECT_TODAY_CONTAINER_DATA_ATTRIBUTE,
} from 'types/todayContainer.constants';
import {
  SELECT_PAYMENT_HISTORY_CONTAINER_DATA,
  SELECT_PAYMENT_HISTORY_CONTAINER_DATA_ATTRIBUTE,
} from 'types/paymentHistoryContainer.constants';
import {
  SELECT_CREDIT_USAGE_CONTAINER_DATA,
  SELECT_CREDIT_USAGE_CONTAINER_DATA_ATTRIBUTE,
} from 'types/creditUsageContainer.constants';
import {
  SELECT_ACCOUNT_MIX_CONTAINER_DATA,
  SELECT_ACCOUNT_MIX_CONTAINER_DATA_ATTRIBUTE,
} from 'types/accountMixContainer.constants';
import {
  SELECT_CREDIT_AGE_CONTAINER_DATA,
  SELECT_CREDIT_AGE_CONTAINER_DATA_ATTRIBUTE,
} from 'types/creditAgeContainer.constants';
import {
  SELECT_CREDIT_INQUIRIES_CONTAINER_DATA,
  SELECT_CREDIT_INQUIRIES_CONTAINER_DATA_ATTRIBUTE,
} from 'types/creditInquiriesContainer.constants';

import { SELECT_DETAILS_OFFER } from 'types/offersDetails.constants';
import { fetchOffers } from 'externals/_services/personalization.service';

import {
  SELECT_POTENTIAL_CREDIT_PROFILE_CONTAINER_DATA,
  SELECT_POTENTIAL_CREDIT_PROFILE_CONTAINER_DATA_ATTRIBUTE,
} from 'types/potentialContainer.constans';
import {
  SELECT_CREDIT_MANAGER_DATA,
  SELECT_CREDIT_MANAGER_DATA_ATTRIBUTE,
} from 'types/creditManagerContainer.constans';
import {
  SELECT_CREDIT_REPORT_TAB_DATA,
  SELECT_CREDIT_REPORT_TAB_DATA_ATTRIBUTE,
} from 'types/creditReportTabContainer.constans';
import {
  SELECT_STATIC_MODULES_TO_MARKETPLACE,
  SELECT_VERTICAL_TO_MARKETPLACE,
} from 'types/offerMarketplace.constants';
import {
  AUTO_LOAN,
  CREDIT_CARD,
  PERSONAL_LOAN,
  LIFE_INSURANCE,
  AUTO_INSURANCE,
  BUSINESS,
  HOME_INSURANCE,
} from 'types/marketplaceTiles.constants';
import {
  createModulesContainer,
  createModulesContainerToAutoInsurance,
  createModulesContainerToAutoLoan,
  createModulesContainerToBusiness,
  createModulesContainerToLifeInsurance,
  createModulesContainerToHomeInsurence,
  createModulesContainerToPersonalLoan,
} from 'pages/Marketplace/pages/OffersPage/hooks/modules_etl';

const a_fetchOffers = wrapService(fetchOffers, {
  name: 'a_fetchOffers',
});

const a_fetchApplyNowTracking = wrapService(fetchApplyNowTracking, {
  name: 'cc_fetchApplyNowTracking',
});

export const selectOneClickApplicationOffer = offerMetadata => ({
  type: SELECT_ONE_CLICK_APPLICATION_OFFER,
  oneClickApplicationOffer: offerMetadata,
});

export const selectDetailsOffer = offerMetadata => ({
  type: SELECT_DETAILS_OFFER,
  offerDetailsData: offerMetadata,
});

export const onCreditCardApplyNow =
  (extraAttributes, extraVariables) => (dispatch, getState) => {
    const state = getState();
    const ocfLoggedIn = state.app.ocfLoggedIn;
    const loggedIn = state.app.loggedIn;
    const userInfo = state.userInfo.userInfo;
    const cashAccount = state.sesameCash.cashAccount;
    const pageNameMeta = state.tracking.pageNameMeta;
    const {
      setIsLoading,
      logger,
      isLoading,
      offerMetadata,
      partnersCustomTrackingMeta,
      partner,
      pagePosition,
      modulePosition,
      history,
      defaultEventHandler,
      position,
      isPrequal,
      containerType,
      isModal,
      isPublicUser,
      urlFromFlag,
      creditOnePrefillEnabled,
    } = extraVariables;

    const onClickTracking = (
      clickId,
      clickTrackingExtraAttributes,
      partnersCustomParams,
    ) => {
      defaultEventHandler(
        CLICK_APPLY,
        Object.assign(
          {
            'Click Type': 'Apply',
            'Click ID': clickId,
            'Page Form Factor': getMrphPageFormFactor(),
          },
          clickTrackingExtraAttributes,
          partnersCustomParams,
        ),
      );
    };
    try {
      const onCreditBuilderApply = () => {
        let redirectTo = '/cash';

        if (isNotEnrolled(userInfo)) {
          redirectTo = '/cash';
        } else if (isEnrolledInCash(userInfo, cashAccount)) {
          redirectTo = '/cb20Enrollment';
        } else if (isEnrolledNotFunded(userInfo, cashAccount)) {
          redirectTo = '/cash';
        } else if (
          isEnrolledAndFunded(userInfo, cashAccount) &&
          !isBoosterEnrolled(cashAccount)
        ) {
          redirectTo = '/cash/builder/enrollmentForm';
        }

        defaultEventHandler(CLICK, {
          'Click Type': extraVariables?.offerMetadata?.ctaActionTitle,
          Vertical: BANKING_VERTICAL,
        });

        history.push(redirectTo);
        setIsLoading(false);
      };
      if (isLoading) {
        return;
      }
      setIsLoading(true);

      logger.debug('Start apply now sequence');
      const id = setPartnerNameAndImageWithId(
        offerMetadata.issuer,
        offerMetadata.imageLink,
      );
      let finalUrl =
        typeof offerMetadata.linkUrl === 'string'
          ? offerMetadata.linkUrl
          : offerMetadata.preApprovalLink;

      if (urlFromFlag) {
        finalUrl = buildEncodedLinkForTrackingApi(urlFromFlag, offerMetadata);
      }

      let attributes = Object.assign(
        {
          'Click Type': 'Apply',
          'Page Name': pageNameMeta,
          'Page Form Factor': getMrphPageFormFactor(),
        },
        extraAttributes,
      );

      const partnersCustomParams = getPartnersCutomTrackingParams(
        offerMetadata,
        {
          pageNameMeta,
          ocfLoggedIn,
          loggedIn,
          partnersCustomTrackingMeta,
        },
      );

      const propsForBackend = convertProps(
        getFullAttributes({
          partner: partner,
          pagePosition: pagePosition,
          modulePosition: modulePosition,
          offerMetadata: offerMetadata,
          position: position,
          extraAttributes: extraAttributes,
          attributes: attributes,
          isModal: isModal,
          isPrequal: isPrequal,
          containerType: containerType,
          includeBackendRefCode: true,
          partnersCustomParams: partnersCustomParams,
        }),
      );

      if (offerMetadata.productKey === 'SESAME_CASH_2') {
        onCreditBuilderApply();
      } else {
        let windowReference = window.open();
        const prefillApplication =
          offerMetadata.issuerEnum === 'CREDIT_ONE_BANK';
        dispatch(a_fetchApplyNowTracking(finalUrl, { props: propsForBackend }))
          .then(trackingMetadata => {
            onClickTracking(
              trackingMetadata.clickId,
              extraAttributes,
              partnersCustomParams,
            );
            windowReference.location =
              plantMetadataInLocalStorageAndReturnTransferUrl(
                trackingMetadata.partnerUrl,
                id,
                prefillApplication && creditOnePrefillEnabled,
                ocfLoggedIn,
                isPublicUser,
              );
            setIsLoading(false);
          })
          .catch(err => {
            windowReference.close();
            logger.showError(
              'A problem happened while Transferring',
              err.message,
            );
            setIsLoading(false);
            logger.checkForGlobalAPIErrorHandler(err).then(_ => {
              logger.errorException(
                'Exception during click apply sequence',
                err,
              );
            });
          });
      }
    } catch (e) {
      logger.showError('A problem happened while Transferring', e.message);
      logger.errorException('Exception before click apply sequence', e);
      setIsLoading(false);
    }
  };

export const onCreditCardfromHostedAppApplyNow =
  (extraAttributes, extraVariables) => (dispatch, getState) => {
    const state = getState();
    const ocfLoggedIn = state?.app?.ocfLoggedIn;
    const loggedIn = state?.app?.loggedIn;
    const pageNameMeta = state?.tracking?.pageNameMeta;
    const {
      logger,
      offerMetadata,
      partnersCustomTrackingMeta,
      history,
      defaultEventHandler,
    } = extraVariables;

    const onClickTracking = (
      clickTrackingExtraAttributes,
      partnersCustomParams,
    ) => {
      defaultEventHandler(
        CLICK_APPLY,
        Object.assign(
          {
            'Click Type': 'Apply',
            'Page Form Factor': getMrphPageFormFactor(),
          },
          clickTrackingExtraAttributes,
          partnersCustomParams,
        ),
      );
    };

    logger.debug('Start apply now from hosted app sequence');
    const finalUrl = `/offers/oneClickApplication/${offerMetadata?.creditCardId}`;
    let attributes = Object.assign(
      {
        'Click Type': 'Apply',
        'Page Name': pageNameMeta,
        'Page Form Factor': getMrphPageFormFactor(),
      },
      extraAttributes,
    );
    const partnersCustomParams = getPartnersCutomTrackingParams(offerMetadata, {
      pageNameMeta,
      ocfLoggedIn,
      loggedIn,
      partnersCustomTrackingMeta,
    });
    onClickTracking(attributes, partnersCustomParams);
    history.push(finalUrl);
  };

const selectContainerData = (
  type,
  attr,
  containerData,
  containerLoading,
  containerError,
) => ({
  type: type,
  [attr]: containerData,
  containerLoading,
  containerError,
});

const startLoadContainerData = type =>
  selectContainerData(type, null, null, true, null);

const successContainerData = (containerData, type, attr) =>
  selectContainerData(type, attr, containerData, false, null);

const failContainerData = (type, err) =>
  selectContainerData(type, null, null, false, err);

const CONTAINER_TYPES = {
  CREDIT_PROFILE: {
    actionType: SELECT_PROFILE_CONTAINER_DATA,
    reducerAttr: SELECT_PROFILE_CONTAINER_DATA_ATTRIBUTE,
  },
  TODAY: {
    actionType: SELECT_TODAY_CONTAINER_DATA,
    reducerAttr: SELECT_TODAY_CONTAINER_DATA_ATTRIBUTE,
  },
  PAYMENT_HISTORY: {
    actionType: SELECT_PAYMENT_HISTORY_CONTAINER_DATA,
    reducerAttr: SELECT_PAYMENT_HISTORY_CONTAINER_DATA_ATTRIBUTE,
  },
  CREDIT_USAGE: {
    actionType: SELECT_CREDIT_USAGE_CONTAINER_DATA,
    reducerAttr: SELECT_CREDIT_USAGE_CONTAINER_DATA_ATTRIBUTE,
  },
  ACCOUNT_MIX: {
    actionType: SELECT_ACCOUNT_MIX_CONTAINER_DATA,
    reducerAttr: SELECT_ACCOUNT_MIX_CONTAINER_DATA_ATTRIBUTE,
  },
  CREDIT_AGE: {
    actionType: SELECT_CREDIT_AGE_CONTAINER_DATA,
    reducerAttr: SELECT_CREDIT_AGE_CONTAINER_DATA_ATTRIBUTE,
  },
  CREDIT_INQUIRIES: {
    actionType: SELECT_CREDIT_INQUIRIES_CONTAINER_DATA,
    reducerAttr: SELECT_CREDIT_INQUIRIES_CONTAINER_DATA_ATTRIBUTE,
  },
  POTENTIAL_CREDIT_PROFILE: {
    actionType: SELECT_POTENTIAL_CREDIT_PROFILE_CONTAINER_DATA,
    reducerAttr: SELECT_POTENTIAL_CREDIT_PROFILE_CONTAINER_DATA_ATTRIBUTE,
  },
  CREDIT_MANAGER: {
    actionType: SELECT_CREDIT_MANAGER_DATA,
    reducerAttr: SELECT_CREDIT_MANAGER_DATA_ATTRIBUTE,
  },
  CREDIT_REPORT_TAB: {
    actionType: SELECT_CREDIT_REPORT_TAB_DATA,
    reducerAttr: SELECT_CREDIT_REPORT_TAB_DATA_ATTRIBUTE,
  },
};

export const getContainerData = (containerName, extraParams) => dispatch => {
  const attrsObject = CONTAINER_TYPES[containerName];
  dispatch(startLoadContainerData(attrsObject?.actionType));

  return new Promise((resolve, reject) => {
    dispatch(a_fetchOffers(containerName, extraParams))
      .then(response => {
        dispatch(
          successContainerData(
            response,
            attrsObject?.actionType,
            attrsObject?.reducerAttr,
          ),
        );
        resolve(response);
      })
      .catch(err => {
        dispatch(failContainerData(attrsObject?.actionType, err));
        reject(err);
      });
  });
};

export const setOfferVertical =
  (selectedVertical, callbackToCreateStaticModules) => dispatch => {
    dispatch({
      type: SELECT_VERTICAL_TO_MARKETPLACE,
      selectedVertical: selectedVertical,
      staticModules: callbackToCreateStaticModules(),
    });
  };

export const redirectPersonalLoanOfferWithStaticModule =
  (history, module, queryParameters) => dispatch => {
    dispatch({
      type: SELECT_VERTICAL_TO_MARKETPLACE,
      selectedVertical: PERSONAL_LOAN,
      staticModules: {
        modules: [{ module }],
      },
    });

    const url = queryParameters
      ? `/offers?vertical=PERSONAL_LOAN&${queryParameters}`
      : `/offers?vertical=PERSONAL_LOAN`;

    history.push(url);
  };

export const setStaticModuleOfMarketplaceOffer = module => dispatch => {
  dispatch({
    type: SELECT_STATIC_MODULES_TO_MARKETPLACE,
    staticModules: {
      modules: [{ module }],
    },
  });
};

export const createPersonalLoanVertical =
  (selectedVertical, staticModules) => dispatch => {
    dispatch(
      setOfferVertical(PERSONAL_LOAN, () =>
        createModulesContainerToPersonalLoan(selectedVertical, staticModules),
      ),
    );
  };

export const createCreditCardVertical = featuredAds => dispatch => {
  dispatch(
    setOfferVertical(CREDIT_CARD, () =>
      createModulesContainer({
        featuredAds,
      }),
    ),
  );
};

export const createHomeInsurenceVertical =
  (selectedVertical, staticModules) => dispatch => {
    dispatch(
      setOfferVertical(HOME_INSURANCE, () =>
        createModulesContainerToHomeInsurence(selectedVertical, staticModules),
      ),
    );
  };

export const createAutoLoanVertical = () => dispatch => {
  dispatch(
    setOfferVertical(AUTO_LOAN, () => createModulesContainerToAutoLoan()),
  );
};

export const createLifeInsuranceVertical = () => dispatch => {
  dispatch(
    setOfferVertical(LIFE_INSURANCE, () =>
      createModulesContainerToLifeInsurance(),
    ),
  );
};

export const createAutoInsuranceVertical = () => dispatch => {
  dispatch(
    setOfferVertical(AUTO_INSURANCE, () =>
      createModulesContainerToAutoInsurance(),
    ),
  );
};

export const createBusinessVertical =
  (verticalBusinessExist, history, selectedVertical) => dispatch => {
    if (verticalBusinessExist) {
      dispatch(
        setOfferVertical(BUSINESS, () => createModulesContainerToBusiness()),
      );
    } else {
      history.push(`/offers?vertical=${selectedVertical}`);
    }
  };
