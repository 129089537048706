import { formatPhoneNumber } from 'helpers/signup.helpers';

import * as csidAlertTypes from 'pages/Alerts/types/csid.constants';
import moment from 'moment';
import { convertTUDatetoFormattedDate } from 'helpers/dateHelper';
import { formatAddress } from 'helpers/address.helper';
import { commafy } from 'helpers/number';

export const getPremiumCategoryText = category => {
  switch (category) {
    case csidAlertTypes.CHANGE_OF_ADDRESS_ALERT:
    case csidAlertTypes.CHANGE_OF_ADDRESS_REPORT:
      return 'Change of Address';
    case csidAlertTypes.COURT_RECORDS_ALERT:
    case 'COURT_RECORDS_REPORT':
      return 'Court Records';
    case csidAlertTypes.CYBER_AGENT_ALERT:
    case 'CYBER_AGENT_REPORT':
      return 'Black Market Website';
    case csidAlertTypes.IDENTITY_TRACE_ALERT:
    case 'IDENTITY_TRACE_REPORT':
      return 'Identity Trace';
    case csidAlertTypes.NON_CREDIT_ALERT:
      return 'Payday Loans';

    case 'NON_CREDIT_REPORT':
      return 'Payday Loans Report';
    case csidAlertTypes.SEX_OFFENDER_ALERT:
    case 'SEX_OFFENDER_REPORT':
      return 'Sex Offender';
    case csidAlertTypes.SSN_TRACE_ALERT:
    case 'SSN_TRACE_REPORT':
      return 'SSN Trace';
    case csidAlertTypes.DARK_WEB_MONITORING:
      return 'Dark Web Alert';
    default:
      return '';
  }
};

const blackMarketRecordMap = {
  'Creation Date': 'CreationDate',
  'Bank Account': 'bankacct',
  'Bank Name': 'bankname',
  CVV: 'cardcvn',
  'Expiration  MM/YY': 'cardexpdate',
  ExpMM: 'cardexpmon',
  ExpYY: 'cardexpyear',
  'Credit Card Number': 'cardnum',
  'Credit Card Type': 'cardtype',
  'Drivers License': 'dlnumber',
  Email: 'email',
  'Medical ID': 'medicalid',
  'Medical Provider': 'medicalprovider',
  'First Name': 'namefirst',
  'Last Name': 'namelast',
  'Passport Country': 'passportcountry',
  'Passport Experation Date': 'passportexpdate',
  'Passport Issue Date': 'passportissuedate',
  'Passport Number': 'passportnum',
  Password: 'password',
  'Social Security Number': 'ssn',
};
const getBlackMarketDetails = (title, alertDetails) => {
  let recordIndex = null;
  const alertRecords = alertDetails.report.record;
  const recordAttribute = blackMarketRecordMap[title];

  alertRecords.forEach((record, index) => {
    if (record[recordAttribute]) {
      recordIndex = index;
    }
  });

  if (recordIndex === null) {
    return null;
  }
  if (title === 'Creation Date' || title === 'Passport Issue Date') {
    return {
      val: convertTUDatetoFormattedDate(
        alertRecords[recordIndex][recordAttribute],
      ),
      source: alertRecords[recordIndex].match,
    };
  }
  if (title === 'Expiration MM/YY') {
    return {
      val: moment(alertRecords[recordIndex][recordAttribute], 'MM/YY'),
      source: alertRecords[recordIndex].match,
    };
  }
  return {
    val: alertRecords[recordIndex][recordAttribute],
    source: alertRecords[recordIndex].match,
  };
};

export const getDarkWebAttribute = (title, alertDetails, category) => {
  const {
    bankAccountNumber,
    dateFound,
    description,
    driverLicenseNumber,
    email,
    insuranceAccount,
    internationalBankAccountNumber,
    locale,
    medicalIdentifier,
    nationalIdentifier,
    passportNumber,
    phone,
    recommendations,
    source,
    type,
    notifiedDate,
  } = alertDetails || {};
  switch (title) {
    case 'Breach Name':
    case 'Password Found?':
      return '';
    case 'Alert Type':
      return 'Dark Web';
    case 'Date Processed':
      return notifiedDate ? convertTUDatetoFormattedDate(notifiedDate) : '';
    case 'Date Found':
      return dateFound ? convertTUDatetoFormattedDate(dateFound) : '';
    case 'Description':
      return description;
    case 'Email Address':
      return email;
    case 'Recommendations':
      return recommendations;
    case 'Source':
      return source;
    case 'Type':
      return type;
    default:
      return '';
  }
};

export const getCsidAttribute = (title, alertDetails, category) => {
  if (
    category === csidAlertTypes.CYBER_AGENT_ALERT ||
    category === 'CYBER_AGENT_REPORT'
  ) {
    return getBlackMarketDetails(title, alertDetails);
  }
  switch (title) {
    case 'Phone':
      if (
        category === csidAlertTypes.NON_CREDIT_ALERT ||
        category === csidAlertTypes.NON_CREDIT_REPORT
      ) {
        return formatPhoneNumber(
          alertDetails?.report?.inquiry?.pda?.detail?.loan?.[0]?.phone,
        );
      }
      return null;
    case 'Address':
      if (
        category === csidAlertTypes.CHANGE_OF_ADDRESS_ALERT ||
        category === csidAlertTypes.CHANGE_OF_ADDRESS_REPORT
      ) {
        return alertDetails?.report?.record?.[0]?.address?.toLowerCase();
      } else if (
        category === csidAlertTypes.SSN_TRACE_ALERT ||
        category === csidAlertTypes.SSN_TRACE_REPORT
      ) {
        const dataPoint =
          alertDetails?.transaction?.data[
            alertDetails?.transaction?.data?.length - 1
          ];
        const { address, city, state, zip } = dataPoint;
        return formatAddress({
          street: address.toLowerCase(),
          city: city.toLowerCase(),
          state: state,
          zip: zip,
        });
      } else if (
        category === csidAlertTypes.NON_CREDIT_ALERT ||
        category === csidAlertTypes.NON_CREDIT_REPORT
      ) {
        return alertDetails?.report?.inquiry?.pda?.detail?.loan?.[0]?.[
          'city-state-zip'
        ];
      }
      return null;
    case 'City':
      if (
        category === csidAlertTypes.CHANGE_OF_ADDRESS_ALERT ||
        category === csidAlertTypes.CHANGE_OF_ADDRESS_REPORT
      ) {
        return alertDetails.report.record[0].city.toLowerCase();
      }
      return null;
    case 'Zip':
      if (
        category === csidAlertTypes.CHANGE_OF_ADDRESS_ALERT ||
        category === csidAlertTypes.CHANGE_OF_ADDRESS_REPORT
      ) {
        return alertDetails.report.record[0].zip;
      }
      return null;
    case 'First Name':
      if (
        category === csidAlertTypes.CHANGE_OF_ADDRESS_ALERT ||
        category === csidAlertTypes.CHANGE_OF_ADDRESS_REPORT
      ) {
        return alertDetails.report.record[0].first;
      } else if (
        category === csidAlertTypes.SSN_TRACE_ALERT ||
        category === csidAlertTypes.SSN_TRACE_REPORT
      ) {
        return alertDetails.transaction.data[0].first.toLowerCase();
      } else if (
        category === csidAlertTypes.NON_CREDIT_ALERT ||
        category === csidAlertTypes.NON_CREDIT_REPORT
      ) {
        let fn = alertDetails.report['first-name'];
        return fn ? fn.toLowerCase() : null;
      }
      return null;
    case 'Last Name':
      if (
        category === csidAlertTypes.SSN_TRACE_ALERT ||
        category === csidAlertTypes.SSN_TRACE_REPORT
      ) {
        return alertDetails.transaction.data[0].last.toLowerCase();
      } else if (
        category === csidAlertTypes.NON_CREDIT_ALERT ||
        category === csidAlertTypes.NON_CREDIT_REPORT
      ) {
        let ln = alertDetails.report['last-name'];
        return ln ? ln.toLowerCase() : null;
      }
      return null;
    case 'Source':
      if (
        category === csidAlertTypes.SSN_TRACE_ALERT ||
        category === csidAlertTypes.SSN_TRACE_REPORT
      ) {
        return alertDetails.transaction.data[0].source;
      }
      return null;
    case 'Transaction Date':
      if (
        category === csidAlertTypes.NON_CREDIT_ALERT ||
        category === csidAlertTypes.NON_CREDIT_REPORT
      ) {
        let td = alertDetails.report['tran-date'];
        return td ? convertTUDatetoFormattedDate(td) : null;
      }
      return null;
    case 'Date':
      if (
        category === csidAlertTypes.NON_CREDIT_ALERT ||
        category === csidAlertTypes.NON_CREDIT_REPORT
      ) {
        return (
          convertTUDatetoFormattedDate(
            alertDetails?.report?.inquiry?.pda?.detail?.loan?.[0]?.date,
          ) || null
        );
      }
      return null;
    case 'Merchant':
      if (
        category === csidAlertTypes.NON_CREDIT_ALERT ||
        category === csidAlertTypes.NON_CREDIT_REPORT
      ) {
        return (
          alertDetails?.report?.inquiry?.pda?.detail?.loan?.[0]?.merchant ||
          null
        );
      }
      return null;
    case 'Loan Amount':
      if (
        category === csidAlertTypes.NON_CREDIT_ALERT ||
        category === csidAlertTypes.NON_CREDIT_REPORT
      ) {
        const amount =
          alertDetails?.report?.inquiry?.pda?.detail?.loan?.[0]?.amount;
        return amount >= 0 ? '$' + commafy(amount) : null;
      }
      return null;
    case 'Status':
      if (
        category === csidAlertTypes.NON_CREDIT_ALERT ||
        category === csidAlertTypes.NON_CREDIT_REPORT
      ) {
        return (
          alertDetails?.report?.inquiry?.pda?.detail?.loan?.[0]?.status || null
        );
      }
      return null;
    default:
      return null;
  }
};

export const getConfigForCSIDAlert = alertType => {
  switch (alertType) {
    case csidAlertTypes.CHANGE_OF_ADDRESS_ALERT:
    case csidAlertTypes.CHANGE_OF_ADDRESS_REPORT:
      return {
        title: getPremiumCategoryText(alertType),
        detailTitles: ['Address', 'City', 'Zip'],
        description:
          "Review your updated address to make sure all the information is accurate. If you see any addresses below that you don't recognize, there may be a public records error. There's also a slight chance that ID fraud occurred.",
        lineType: 'neutral',
        image: true,
        callHelp: true,
      };
    case csidAlertTypes.SSN_TRACE_ALERT:
    case csidAlertTypes.SSN_TRACE_REPORT:
      return {
        title: getPremiumCategoryText(alertType),
        detailTitles: ['Address', 'First Name', 'Last Name', 'Source'],
        description:
          'The following information is associated with your Social Security number.',
        image: true,
        lineType: 'neutral',
        callHelp: true,
      };
    case csidAlertTypes.NON_CREDIT_ALERT:
    case csidAlertTypes.NON_CREDIT_REPORT:
      return {
        title: getPremiumCategoryText(alertType),
        multiDetailList: true,
        detailTitles: [
          {
            header: 'Summary',
            titles: [
              'First Name',
              'Last Name',
              'Date',
              'Loan Amount',
              'Status',
            ],
          },
          {
            header: 'Merchant Details',
            titles: ['Merchant', 'Address', 'Phone'],
          },
        ],
        description:
          'Review your payday loans to make sure all of the information is accurate.',
        image: true,
        lineType: 'neutral',
        callHelp: true,
      };
    case csidAlertTypes.CYBER_AGENT_ALERT:
    case csidAlertTypes.CYBER_AGENT_REPORT:
      return {
        title: getPremiumCategoryText(alertType),
        multiDetailList: true,
        detailTitles: [
          {
            header: 'Personal Information',
            titles: ['First Name', 'Last Name', 'Social Security Number'],
          },
          {
            header: 'Credit/ Debit Cards',
            titles: [
              'Credit Card Type',
              'Expiration  MM/YY',
              'Credit Card Number',
              'CVV',
            ],
          },
          {
            header: 'Email Address',
            titles: ['Email', 'Password', 'Creation Date'],
          },
          {
            header: 'Bank Accounts',
            titles: ['Bank Account', 'Bank Name'],
          },
          {
            header: 'Drivers License',
            titles: ['Drivers License'],
          },
          {
            header: 'Medical ID',
            titles: ['Medical ID'],
          },
          {
            header: 'Passport',
            titles: [
              'Passport Number',
              'Passport Issue Date',
              'Passport Experation Date',
              'Passport Country',
            ],
          },
        ],
        description:
          'We found the following information on black market websites.',
        image: true,
        lineType: 'neutral',
        callHelp: true,
      };
    case csidAlertTypes.DARK_WEB_MONITORING:
      return {
        title: getPremiumCategoryText(alertType),
        detailTitles: [
          'Alert Type',
          'Date Processed',
          'Breach Name',
          'Date Found',
          'Description',
          'Email Address',
          'Password Found?',
          'Recommendations',
          'Source',
          'Type',
        ],
        description:
          'We found the following information on black market websites.',
        image: true,
        lineType: 'neutral',
        callHelp: true,
      };
    default:
      return [];
  }
};
